<script>
import validate from "@/helpers/validate";
import { login } from "@/helpers/auth";

export default {
  data() {
    return {
      user: {
        login: '',
        password: '',
        remember: false,
      },
      passType: false,
      errors: {},
      loading: false,
    }
  },
  methods: {
    login() {
      this.errors = {};
      this.errors.login = validate.loginOrEmail(this.user.login);
      this.errors.password = validate.password(this.user.password);

      if (this.errors.email ||
          this.errors.login ||
          this.errors.password ||
          this.errors.confirm) return;
      this.loading = true;
      login(this.user, {
        end: () => {
          this.loading = false;
        }
      });
    }
  }
}
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40">
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">Добро пожаловать домой!</p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4" data-aos="fade-up">
              <div v-if="loading" class="card-preloader d-flex flex-column align-items-center justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
                <p class="mt-2">Авторизация...</p>
              </div>

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">С возвращением!</h5>
                  <p class="text-muted">Авторизуйтесь для продолжения</p>
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="login">
                    <div class="mb-3">
                      <label for="login" class="form-label">Имя пользователя</label>
                      <input
                          type="text"
                          class="form-control"
                          v-model="user.login"
                          id="login"
                          placeholder="Введите email или ник"
                          :class="{
                            'is-invalid': errors.login,
                          }"
                      >

                      <div
                          v-for="(item, index) in errors.login"
                          :key="index"
                          class="invalid-feedback"
                      >
                        <span v-if="item"> {{ item }} </span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot" class="text-muted">Забыли пароль?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Пароль</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                            :type="passType ? 'text' : 'password'"
                            v-model="user.password"
                            class="form-control pe-5"
                            placeholder="Введите пароль"
                            id="password-input"
                            :class="{
                              'is-invalid': errors.password,
                            }"
                        >
                        <button class="btn btn-link position-absolute top-0 text-decoration-none text-muted"
                                style="right: -35px"
                                type="button"
                                id="password-addon"
                                @click="passType = !passType"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>

                        <div
                            v-for="(item, index) in errors.password"
                            :key="index"
                            class="invalid-feedback"
                        >
                          <span v-if="item"> {{ item }} </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input v-model="user.remember" class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                      <label class="form-check-label" for="auth-remember-check">Запомнить меня</label>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit">Войти</button>
                    </div>

  <!--                    <div class="mt-4 text-center">-->
  <!--                      <div class="signin-other-title">-->
  <!--                        <h5 class="fs-13 mb-4 title">Войти с</h5>-->
  <!--                      </div>-->
  <!--                      <div>-->
  <!--                        <button type="button" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ri-facebook-fill fs-16"></i></button>-->
  <!--                        <button type="button" class="btn btn-danger btn-icon waves-effect waves-light ms-1"><i class="ri-google-fill fs-16"></i></button>-->
  <!--                        <button type="button" class="btn btn-dark btn-icon waves-effect waves-light ms-1"><i class="ri-github-fill fs-16"></i></button>-->
  <!--                        <button type="button" class="btn btn-info btn-icon waves-effect waves-light ms-1"><i class="ri-twitter-fill fs-16"></i></button>-->
  <!--                      </div>-->
  <!--                    </div>-->
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center" data-aos="fade-in">
              <p class="mb-0">У Вас нет аккаунта? <router-link to="/register" class="fw-semibold text-primary text-decoration-underline"> Зарегистрируйтесь </router-link> </p>
            </div>

          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{new Date().getFullYear()}} FinalDream. Сделано с любовью
                <i class="mdi mdi-heart text-danger"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>